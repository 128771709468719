export function valueIntoFilter(value, scriptData, editMap) {
    // TODO: добавить сюда еще поле ввода каунт для количества игроков (пока хз как)
    let filter = null;
    let errorMsg = '';
    console.log('valueIntoFilter:', value);
    switch (value) {
      case 'characters_alive_all':
      case 'creator_alive':
        filter = {[value]: true};
        break;

      case 'in_win_cells_all':
        filter = {[value]: []}
        for (let i = 0; i < scriptData.header.size[0]; i++) {
          for (let j = 0; j < scriptData.header.size[1]; j++) {
            if (editMap.cells[`${i}-${j}`]?.win === true) {
              filter[value].push([i, j]);
            }
          }
        }

        if (filter[value].length === 0) {
          errorMsg = 'Не выбрано ни одной победной клетки.';
          filter = null;
        }

        break;

        default:
          errorMsg = 'Ошибка при выборе условия (фильтр не реализован).'
          break;
    }

    return [filter, errorMsg];
}
