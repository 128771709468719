import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { valueIntoFilter } from '../utils';


const IndexPage = () => {
  const [scriptsConfig, setScriptsConfig] = useState({});
  const [textCounters, setTextCounters] = useState({'name': 0, 'description': 0, 'target': 0});
  const [editCell, setEditCell] = useState({row: 1, col: 1});
  const [editMap, setEditMap] = useState({cells: {}});

  const [scriptData, setScriptData] = useState({
    'header': {
      'name': '',
      'description': '',
      'target_description': '',
      'size': [5,5],
      'start_position': [1,1],
      'result_filters': [],
    },
    'map': {
      'move': {
        'default': 'В тусклом свете факела вы видите каменные стены пещеры, покрытые капельками влаги.',
        'ban': 'Вы уперлись в каменную стену, туда не пройти.',
      },
      'cells': []
    },
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const initializeAll = async () => {
    setLoading(true);
    setError(null);
    try {
      let resp = await axios.get(process.env.REACT_APP_API_ENDPOINT + 'scripts/config');
      setScriptsConfig(resp.data);
      initializeEditMap(scriptData.header.size[0], scriptData.header.size[1]);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const initializeEditMap = (rows, cols) => {
    setEditMap(prevEditMap => {
      let newCells = { ...prevEditMap.cells };
      for (let i = 0; i <= rows; i++) {
        for (let j = 0; j <= cols; j++) {
          if (!newCells[`${rows - i}-${j}`]) {
            newCells[`${rows - i}-${j}`] = {
              move: scriptData.map.move.ban,
              walkable: false,
              win: false,
            };
          }
        }
      }
      return { cells: newCells };
    });
  }

  const renderMap = (x, y) => {
    let rows = [];
    for (let i = 0; i <= x; i++) {
      let cells = [];
      for (let j = 0; j <= y; j++) {
        const row = x-i;
        const col = j;
        let activeClass = '';
        let walkableClass = '';
        let winClass = '';
        if (row === editCell.row && col === editCell.col)
          activeClass = 'active-cell';
        if (editMap.cells[`${row}-${col}`]?.walkable === false)
          walkableClass = 'wall-cell';
        if (editMap.cells[`${row}-${col}`]?.win === true)
          winClass = 'win-cell';

        if (i === x && j === 0) {
          cells.push(<div key={`${row}-${col}`} className='cell header-cell'></div>);
        } else if (i === x) {
          cells.push(<div key={`${row}-${col}`} className='cell header-cell'>{col}</div>);
        } else if (j === 0) {
          cells.push(<div key={`${row}-${col}`} className='cell header-cell'>{row}</div>);
        } else {
          cells.push(<div key={`${row}-${col}`} className={`cell ${activeClass} ${walkableClass} ${winClass}`} onClick={() => handleCellClick(row, col)}></div>);
        }
      }
      rows.push(
        <div className='d-flex'>
          {cells}
        </div>
      );
    }
    return rows;
  };

  useEffect(() => {
    initializeAll();
  }, []);

  const handleCellClick = (row, col) => {
    setEditCell({ row, col });
  };

  const handleWalkableRadioChange = (event) => {
    const walkable = event.target.value === 'true';
    const move = walkable ? scriptData.map.move.default : scriptData.map.move.ban;
    const { row, col } = editCell;
    let win = editMap.cells[`${row}-${col}`]?.win;
    if (walkable === false && win === true) {
      win = false;
    }

    setEditMap((prevEditMap) => ({
      ...prevEditMap,
      cells: {
        ...prevEditMap.cells,
        [`${row}-${col}`]: {
          ...prevEditMap.cells[`${row}-${col}`],
          walkable: walkable,
          move: move,
          win: win,
        },
      },
    }));
  };

  const handleWinRadioChange = (event) => {
    const win = event.target.value === 'true';
    const { row, col } = editCell;
    let walkable = editMap.cells[`${row}-${col}`]?.walkable;
    if (walkable === false && win === true) {
      walkable = true;
    }

    setEditMap((prevEditMap) => ({
      ...prevEditMap,
      cells: {
        ...prevEditMap.cells,
        [`${row}-${col}`]: {
          ...prevEditMap.cells[`${row}-${col}`],
          walkable: walkable,
          win: win,
        },
      },
    }));
  };

  const handleSizeChange = (event) => {
    event.preventDefault();
    let { id, value } = event.target;
    value = Number(value);

    if (value > scriptsConfig.constants.size) {
      event.target.value = scriptsConfig.constants.size;
      value = scriptsConfig.constants.size;
    }

    if (value < 1) {
      event.target.value = 1;
      value = 1;
    }

    setScriptData((prevData) => {
      const newSize = [...prevData.header.size];
      const newStartPosition = [...prevData.header.start_position];
      if (id === 'size-row') {
        newSize[1] = value;
        if (newStartPosition[1] > newSize[1]) {
          newStartPosition[1] = newSize[1];
        }
      } else if (id === 'size-col') {
        newSize[0] =value;
        if (newStartPosition[0] > newSize[0]) {
          newStartPosition[0] = newSize[0];
        }
      }

      initializeEditMap(newSize[0], newSize[1]);

      return {
        ...prevData,
        header: {
          ...prevData.header,
          size: newSize,
          start_position: newStartPosition,
        },
      };
    });
  }

  const handleStartChange = (event) => {
    event.preventDefault();
    let { id, value } = event.target;
    value = Number(value);

    if (value > scriptsConfig.constants.size) {
      event.target.value = scriptsConfig.constants.size;
      value = scriptsConfig.constants.size;
    }

    if (value < 1) {
      event.target.value = 1;
      value = 1;
    }

    setScriptData((prevData) => {
      const newStartPosition = [...prevData.header.start_position];
      if (id === 'start-col') {
        newStartPosition[0] = value;
      } else if (id === 'start-row') {
        newStartPosition[1] = value;
      }

      return {
        ...prevData,
        header: {
          ...prevData.header,
          start_position: newStartPosition,
        },
      };
    });
  }

  const handleTextInputChange = (event) => {
    event.preventDefault();
    let { id, value } = event.target;

    if (id === 'cell-move') {
      const { row, col } = editCell;
      setEditMap((prevEditMap) => ({
        ...prevEditMap,
        cells: {
          ...prevEditMap.cells,
          [`${row}-${col}`]: {
            ...prevEditMap.cells[`${row}-${col}`],
            move: value,
          },
        },
      }));
    }

    if (id === 'name') {
      setScriptData((prevData) => ({
        ...prevData,
        header: {
          ...prevData.header,
          name: value,
        }
      }));
    }

    if (id === 'description') {
      setScriptData((prevData) => ({
        ...prevData,
        header: {
          ...prevData.header,
          description: value,
        }
      }));
    }

    if (id === 'target') {
      setScriptData((prevData) => ({
        ...prevData,
        header: {
          ...prevData.header,
          target_description: value,
        }
      }));
    }

    setTextCounters((prevCounters) => ({
      ...prevCounters,
      [id]: value.length,
    }));
  };

  const handleAddResultFilter = (event) => {
    event.preventDefault();
    let filter = {'value': 'none', 'none': true, 'id': scriptData.header.result_filters.length};
    setScriptData((prevData) => {
      const newResultFilters = [...prevData.header.result_filters];
      newResultFilters.push(filter);

      return {
        ...prevData,
        header: {
          ...prevData.header,
          result_filters: newResultFilters,
        },
      };
    });
  };

  const handleRemoveResultFilter = (id) => {
    setScriptData(prevData => ({
      ...prevData,
      header: {
        ...prevData.header,
        result_filters: prevData.header.result_filters.filter(filter => filter.id !== id)
      }
    }));
  };

  const handleSelectFilterChange = (id, event) => {
    cleanupErrorMessage();

    console.log('handleSelectFilterChange', id, event.target.value);
    if (event.target.value === 'none') {
      showErrorMessage('Выберите условие.');
      return;
    }

    for (let i = 0; i < scriptData.header.result_filters.length; i++) {
      const item = scriptData.header.result_filters[i];
      if (item.value === event.target.value) {
        showErrorMessage('Условия не должны повторяться.');
        event.target.value = 'none';
        return;
      }
    }

    let [filter, errorMsg] = valueIntoFilter(event.target.value, scriptData, editMap);
    if (filter === null) {
      showErrorMessage(errorMsg);
      event.target.value = 'none';
      return;
    }

    setScriptData((prevData) => {
      const newResultFilters = [...prevData.header.result_filters];
      filter['id'] = id;
      filter['value'] = event.target.value;
      const index = newResultFilters.findIndex(obj => obj.id === id);
      newResultFilters[index] = filter;

      return {
        ...prevData,
        header: {
          ...prevData.header,
          result_filters: newResultFilters,
        },
      };
    });
  };

  const handleDownloadButton = async (event) => {
    event.preventDefault();
    cleanupErrorMessage();

    let errorMessage = '';
    if (scriptData.header.name.length === 0) {
      errorMessage += 'Нужно ввести название сценария.<br>';
    }
    if (scriptData.header.description.length === 0) {
      errorMessage += 'Нужно ввести описание сценария.<br>';
    }

    if (scriptData.header.target_description.length === 0) {
      errorMessage += 'Нужно ввести описание цели сценария.<br>';
    }

    if (scriptData.header.result_filters.length === 0) {
      errorMessage += 'Нужно выбрать хотя бы одно условие завершения сценария.<br>';
    }

    let updatedResultFilters = [ ...scriptData.header.result_filters ];
    for (let i = 0; i < updatedResultFilters.length; i++) {
      delete updatedResultFilters[i].value;
      delete updatedResultFilters[i].id;
    }

    let walkableCells = [];
    let updatedMapMove = { ...scriptData.map.move };
    let startPositionWalkable = false;
    for (let i = 0; i <= scriptData.header.size[0]; i++) {
      for (let j = 0; j <= scriptData.header.size[1]; j++) {
        const row = scriptData.header.size[0]-i;
        const col = j;
        if (editMap.cells[`${row}-${col}`]?.walkable === true) {
          console.log(scriptData.header.start_position, col, row);
          if (row === scriptData.header.start_position[1] && col === scriptData.header.start_position[0]) {
            startPositionWalkable = true;
          }

          let move = editMap.cells[`${row}-${col}`].move;
          if (move === scriptData.map.move.default) {
            walkableCells.push({'x': col, 'y': row});
          }
          else {
            let addNewMove = true;
            for (const moveKey in scriptData.map.move) {
              const moveElement = scriptData.map.move[moveKey];
              if (move === moveElement) {
                walkableCells.push({'x': row, 'y': col, 'move': moveKey});
                addNewMove = false;
                break;
              }
            }

            if (addNewMove) {
              let newMoveKey = 1;
              while (true) {
                if (String(newMoveKey) in scriptData.map.move) {
                  newMoveKey += 1;
                }
                else {
                  scriptData.map.move[String(newMoveKey)] = move;
                  break;
                }
              }

              newMoveKey = String(newMoveKey);
              updatedMapMove[newMoveKey] = move;
              walkableCells.push({'x': col, 'y': row, 'move': newMoveKey});
            }
          }
        }
      }
    }

    const newScriptData = await new Promise((resolve) => {
      setScriptData((prevData) => {
        const newData = {
          ...prevData,
          header: {
            ...prevData.header,
            result_filters: updatedResultFilters,
          },
          map: {
            ...prevData.map,
            move: updatedMapMove,
            cells: walkableCells,
          },
        };
        resolve(newData);
        return newData;
      });
    }).then((newData) => {
      return newData;
    });

    if (newScriptData.map.cells.length === 0) {
      errorMessage += 'Нужна хотя бы одна клетка на которую можно наступать.<br>';
    }

    if (startPositionWalkable === false) {
      errorMessage += 'Стартовая позиция должна быть на клетке, по которой можно ходить.<br>';
    }

    if (newScriptData.header.start_position[0])

    if (errorMessage !== '') {
      showErrorMessage(errorMessage);
      return;
    }

    console.log(newScriptData);
    downloadScriptData(newScriptData);
  }

  const downloadScriptData = async (scriptData) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT + 'scripts/pack', {'header': scriptData.header, 'map': scriptData.map});
      const data = response.data.data;
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
      element.setAttribute('download', 'script.dmbs');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  function showErrorMessage(msg) {
    document.getElementById('error-message-bot').innerHTML = msg;
  }

  function cleanupErrorMessage() {
    document.getElementById('error-message-bot').innerHTML = '';
  }

  const CellWalkableEditor = ({ editCell, editMap, handleWalkableRadioChange }) => (
    <>
      <p><span style={{ marginRight: '1rem' }}>Могут ли персонажи наступать на клетку:</span>
        <input type='radio' name='cellWalkable' value='true' checked={editMap.cells[`${editCell.row}-${editCell.col}`]?.walkable === true} onChange={handleWalkableRadioChange} /> да
        <input type='radio' name='cellWalkable' value='false' checked={editMap.cells[`${editCell.row}-${editCell.col}`]?.walkable === false} onChange={handleWalkableRadioChange} style={{ marginLeft: '1rem' }} /> нет
      </p>
    </>
  );

  const CellWinEditor = ({ editCell, editMap, handleWinRadioChange}) => (
    <>
      <p><span style={{ marginRight: '1rem' }}>Выигрышная клетка:</span>
        <input type='radio' name='cellWin' value='true' checked={editMap.cells[`${editCell.row}-${editCell.col}`]?.win === true} onChange={handleWinRadioChange}  /> да
        <input type='radio' name='cellWin' value='false' checked={editMap.cells[`${editCell.row}-${editCell.col}`]?.win === false} onChange={handleWinRadioChange} style={{ marginLeft: '1rem' }} /> нет
      </p>
    </>
  );

  if (loading) return <p className='text-info'>Loading...</p>;
  else if (error) return <p>API error: <span className='text-danger'>{error.message}</span></p>;
  else
    return (
      <div className='container'>
        <h1 className='text-center'>Редактор сценария для Dungeons Master Bot</h1>
        <p>Тут нужна базовая справка че это и ссылка на полный гайд.</p>

        <form>
          <h2>Основная информация о сценарии</h2>
          <div className='text-danger' id='error-message-top'></div>
          <label for='name'>Название ({textCounters['name']}/{scriptsConfig.constants.name}):</label>
          <input type='text' className='form-control mb-4' onChange={handleTextInputChange} id='name' maxLength={scriptsConfig.constants.name} placeholder='Пустая пещера минотавра.'></input>
          <label for='description'>Описание ({textCounters['description']}/{scriptsConfig.constants.description}):</label>
          <textarea className='form-control mb-4' onChange={handleTextInputChange} id='description' rows='3' maxLength={scriptsConfig.constants.description} placeholder='Поздравляю искатели приключений! Вы наткнулись на пещеру минотавра, по слухам он охраняет великий артефакт. Однако, пещера выглядит подозрительно пустой.'></textarea>
          <label for='target'>Цель ({textCounters['target']}/{scriptsConfig.constants.target}):</label>
          <textarea className='form-control mb-4' onChange={handleTextInputChange} id='target' rows='3' maxLength={scriptsConfig.constants.target} placeholder='Вам предствоит найти минотавра и забрать артефакт себе! Или просто выйти из пещеры с другой стороны. Если минотавр так и не найдется.'></textarea>

          <h2>Карта сценария</h2>
          <div className='container mb-4'>
            <div class='d-flex form-row align-items-center mb-4'>
              <div class='col-sm-0'>
                <label>Размер:</label>
              </div>
              <div class='col-sm-0'>
                <input type='number' min={1} max={scriptsConfig.constants.size} className='form-control size-input' id='size-col' value={scriptData.header.size[0]} onChange={handleSizeChange} placeholder={scriptData.header.size[0]} />
              </div>
              <div class='col-sm-0'>
                <input type='number' min={1} max={scriptsConfig.constants.size} className='form-control size-input' id='size-row' value={scriptData.header.size[1]} onChange={handleSizeChange} placeholder={scriptData.header.size[1]} />
              </div>
            </div>

            <div className='row d-flex'>
              <div className='col container fixed-container'>
                {renderMap(scriptData.header.size[0], scriptData.header.size[1])}
              </div>

              <div className='col control-panel'>
                <p>Редактирование клетки: {editCell.col} / {editCell.row}</p>
                <CellWalkableEditor editCell={editCell} editMap={editMap} handleWalkableRadioChange={handleWalkableRadioChange} />
                <CellWinEditor editCell={editCell} editMap={editMap} handleWinRadioChange={handleWinRadioChange} />
                <p>
                  <label htmlFor='cell-move'>Описание:</label>
                  <input type='textarea' className='form-control' rows='3' id='cell-move' maxLength='250' value={editMap.cells[`${editCell.row}-${editCell.col}`]?.move || ''} onChange={handleTextInputChange} placeholder={editMap.cells[`${editCell.row}-${editCell.col}`]?.move || ''} />
                </p>
              </div>
            </div>
            <div class='d-flex form-row align-items-center mb-4'>
              <div class='col-sm-0'>
                <label>Стартовая позиция:</label>
              </div>
              <div class='col-sm-0'>
                <input type='number' min={1} max={scriptData.header.size[0]} className='form-control size-input' id='start-col' value={scriptData.header.start_position[0]} onChange={handleStartChange} placeholder={scriptData.header.start_position[0]} />
              </div>
              <div class='col-sm-0'>
                <input type='number' min={1} max={scriptData.header.size[1]} className='form-control size-input' id='start-row' value={scriptData.header.start_position[1]} onChange={handleStartChange} placeholder={scriptData.header.start_position[1]} />
              </div>
            </div>
          </div>

          <h2>Условия завершения сценария</h2>
          <p>Список условий, при которых игроки либо побеждают, либо проигрывают. Сценарий завершается, если хотя бы одно из условий было достигнуто.</p>
          {scriptData.header.result_filters.map(edit_filter => (
            <div className='d-flex align-items-center mb-3'>
              <select className='form-control' value={edit_filter.value} onChange={(e) => handleSelectFilterChange(edit_filter.id, e)} style={{ marginRight: '10px' }}>
                {scriptsConfig.result_filters.map(config_filter => (
                  <option value={config_filter.name}>{config_filter.description}</option>
                ))}
              </select>
              <button type='button' className='btn btn-sm btn-danger' onClick={() => handleRemoveResultFilter(edit_filter.id)}>Удалить</button>
            </div>
          ))}
          <button type='button' className='btn btn-success' onClick={handleAddResultFilter}>Добавить условие</button>

          <button className='btn btn-primary d-block mt-4' type='submit' onClick={handleDownloadButton}>Скачать файл сценария</button>
          <div className='text-danger' id='error-message-bot'></div>
        </form>
      </div>
    );
};

export default IndexPage;
