import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import IndexPage from './pages/IndexPage';
import NotFoundPage from './pages/NotFoundPage';


const App = () => {
  return (
    <Router>
      <div>
        <nav className='navbar navbar-expand-lg navbar-light bg-light'>
            <a className='navbar-brand' href='https://dmbot.io' target='_blank' rel='noopener noreferrer'>DMBot</a>
            <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
              <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse'>
              <ul className='navbar-nav'>
                <li className='nav-item'>
                  <Link className='nav-link' to='/'>Home</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <Routes>
          <Route exact path='/' element={<IndexPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
        <footer className='footer mt-5 py-3 bg-light'>
          <div className='container'>
              <span className='text-muted'>&copy; 2024 Dungeons Master Bot</span>
          </div>
        </footer>
    </Router>
  );
};

export default App;
